
import {
    defineComponent, onBeforeUnmount, onMounted, reactive, WritableComputedRef, PropType,
} from 'vue';
import Shipment from '@/domain/Shipment';
import TextInput from '@/components/inputs/TextInput.vue';
import Location from '@/domain/Location';
import Carrier from '@/domain/Carrier';
import Dock from '@/domain/Dock';
import TrailerType from '@/domain/TrailerType';
import Transaction from '@/domain/Transaction';
import CoreStore from '@/store/CoreStore';
import TrailerTypeService from '@/services/TrailerTypeService';
import DateInput from '@/components/inputs/DateInput.vue';
import TagListener from '@/modules/floortrak/services/TagListener';
import LocationService from '@/services/LocationService';
import useModelWrapper from '@/composable/useModelWrapper';
import { ValidationResult } from '@/validation/types';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import { daysBack } from '@/functions/date';
import DockService from '@/services/DockService';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import NonWorkDayService from '@/services/NonWorkDayService';
import RouteConfigService from '@/services/RouteConfigService';

type State = {
    transaction: Transaction;
    fromLocations: Array<Location>;
    trailerTypes: Array<TrailerType>;
    loading: boolean;
    docks: Array<Dock>;
    earliestPossibleShipDate?: Date;
    nonWorkDays: Date[];
};

export default defineComponent({
    name: 'receiving-transaction-form',
    components: {
        DropdownAutocompleteSingleSelect,
        TextInput,
        DateInput,
    },
    props: {
        modelValue: {
            type: Object as PropType<Shipment>,
            default: () => new Shipment(),
        },
        carriers: {
            type: Array as PropType<Carrier[]>,
            required: true,
        },
        validationResult: {
            type: Object as PropType<ValidationResult<Shipment>>,
            default: undefined,
        },
    },
    emits: ['update:modelValue', 'fromLocationChanged'],

    setup(props, context) {
        const { configStore, timeSetStore } = CoreStore.getInstance();
        const { userLocation } = CoreStore.getInstance().profileStore;
        const trailerService = new TrailerTypeService();
        const locationService = new LocationService();
        const dockService = new DockService();
        const nonWorkDayService = new NonWorkDayService(timeSetStore.allTimeSets);
        const routeConfigService = new RouteConfigService();

        const arrivalDateLowerLimit = new Date(daysBack(14).setHours(0, 0, 0));
        const arrivalDateUpperLimit = new Date(new Date().setHours(23, 59, 59));

        const state = reactive<State>({
            transaction: new Transaction(),
            fromLocations: [],
            trailerTypes: [],
            loading: false,
            docks: [],
            nonWorkDays: [],
        });

        const shipment: WritableComputedRef<Shipment> = useModelWrapper(props, context, 'modelValue');

        function ensureShipmentTransaction(): Transaction {
            let transaction = new Transaction();

            if (shipment.value.transactions.length > 0) {
                transaction = shipment.value?.transactions[0];
            } else {
                shipment.value.addTransaction(transaction);
            }

            transaction.toLocation = userLocation;

            return transaction;
        }

        onMounted(async () => {
            state.loading = true;

            state.fromLocations = await locationService.getCanReceiveFromLocations(userLocation.id);
            state.docks = await dockService.getInServiceReceivingDocksByLocationId(userLocation.id);
            if(state.docks.length === 1)
                shipment.value.dock = state.docks[0];

            const trailerTypes = await trailerService.getAllTrailerTypes();
            if (trailerTypes.success) {
                state.trailerTypes = trailerTypes.trailerTypes;
            }

            state.transaction = ensureShipmentTransaction();

            state.loading = false;
        });

        function selectTrailerType(trailerType: TrailerType | null) {
            if (trailerType) {
                shipment.value.trailerType = trailerType;
            } else {
                shipment.value.trailerType = new TrailerType();
            }
        }

        async function selectFromLocation(loc: Location | null) {
            if (loc) {
                state.transaction.fromLocation = loc;
                shipment.value.fromLocation = loc;

                state.loading = true;
                const shipmentRouteConfigPromise = routeConfigService
                    .getRouteConfigByFromAndToLocation(state.transaction.fromLocationId, state.transaction.toLocationId);
                const trailerType = (await trailerService.getPreferredTrailerTypeForRoute(loc.id, state.transaction.toLocation.id)) ?? configStore.preferredTrailerType;
                selectTrailerType(trailerType);
                const transactionArrivalDate = shipment.value.transactions[0].arrivedAt;

                let transitTime = (await shipmentRouteConfigPromise)?.transitTime;
                if (!transitTime) {
                    transitTime = 172800000; // if no route exists, transitTime should be 2 days
                }

                const calculatedShipDate = nonWorkDayService.getPreviousWorkingDay(new Date(
                    transactionArrivalDate.getTime() - transitTime,
                ), loc.nonWorkingTimeSetId);

                ({ newShipDateMin: state.earliestPossibleShipDate, newShipDateDisabled: state.nonWorkDays } = nonWorkDayService
                    .getShipDateCalendarDataBetweenDates(new Date(calculatedShipDate), 90, new Date(transactionArrivalDate), 5, loc.nonWorkingTimeSetId));

                state.transaction.shipDate = calculatedShipDate;
                state.loading = false;

                context.emit('fromLocationChanged', loc);
            } else {
                state.transaction.fromLocation = new Location();
                shipment.value.fromLocation = new Location();
                selectTrailerType(null);
            }
        }

        function selectCarrier(carrier: Carrier | null) {
            if (carrier) {
                shipment.value.carrierId = carrier.id;
                shipment.value.carrier = carrier;
            } else {
                shipment.value.carrierId = undefined;
                shipment.value.carrier = undefined;
            }
        }

        function selectDock(dock: Dock | null) {
            if (dock) {
                shipment.value.dock = dock;
            } else {
                shipment.value.dock = undefined;
            }
        }

        function setArrivalDate(arrivalDate: Date | null) {
            shipment.value.arrivalDate = arrivalDate;
            state.transaction.arrivedAt = arrivalDate as any;
        }

        function setShipDate(shipDate: Date | null) {
            state.transaction.shipDate = shipDate as any;
        }

        return {
            shipment,
            state,
            selectFromLocation,
            selectCarrier,
            selectTrailerType,
            setArrivalDate,
            arrivalDateLowerLimit,
            arrivalDateUpperLimit,
            selectDock,
            getTranslation,
            getTitleCaseTranslation,
            setShipDate,
        };
    },
});
