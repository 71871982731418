
import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import Shipment from '@/domain/Shipment';
import ReceivingTransactionForm from '@/modules/floortrak/view/receiving/components/TransactionForm.vue';
import Transaction from '@/domain/Transaction';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import useValidator from '@/validation/useValidator';
import ReceivingService from '@/services/ReceivingService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Permissions from '@/services/permissions/Permissions';
import LabelGenerationService from '@/services/LabelGenerationService';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import InboundShipment from '@/domain/InboundShipment';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import Carrier from '@/domain/Carrier';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import Location from '@/domain/Location';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionService from '@/services/SafetyInspectionService';
import { CoreStore } from '@/store/CoreStore';
import { TransactionStatus } from '@/domain/TransactionStatus';
import Item from '@/domain/Item';
import TransactionLine from '@/domain/TransactionLine';
import { ItemType } from '@/domain/enums/ItemType';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import QuantityPicker from '@/components/QuantityPicker.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import ReceivingAction from '@/modules/floortrak/domain/enums/ReceivingAction';
import Thumbnail from '@/components/Thumbnail.vue';
import TagListener from '@/modules/floortrak/services/TagListener';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import TrackedItem from '@/domain/TrackedItem';
import FloortrakReceivingTagScanHandler from '@/services/tag-scanning/scan-handler/FloortrakReceivingTagScanHandler';
import ReservationService from '@/services/ReservationService';
import PlannedReservation from '@/domain/PlannedReservation';
import { formatDateTimeWithPeriodUI } from '@/functions/date';
import { useNotification } from '@/composable/useNotifications';
import AllOrOnRouteItemSelector from '@/components/AllOrOnRouteItemSelector.vue';

type State = {
    newShipment: Shipment;
    disableSave: boolean;
    isDownloading: boolean;
    showModal: boolean;
    modalTitle: string;
    isEstimate: boolean;
    isSort: boolean;
    showPrintLabelsModal: boolean;
    labelQuantity: number | undefined;
    printingLabels: boolean;
    transactionId: number | undefined;
    showInboundModal: boolean;
    inboundShipments: InboundShipment[];
    carriers: Carrier[];
    fromLocation: Location;
    preUnloadSafetyInspection: SafetyInspection;
    showPreUnloadSafetyInspection: boolean;
    showEquipmentInspection: boolean;
    loading: boolean;
    showItemSearch: boolean;
    unloadComplete: boolean;
    showPostUnloadSafetyInspection: boolean;
    postUnloadSafetyInspection: SafetyInspection;
    submittingEstimate: number | null;
    tags: Array<TrackedItem>;
    inboundPlannedReservations: PlannedReservation[];
    plannedReservation: PlannedReservation | null;
    isDirty: boolean;
};

interface ItemTableData {
    item: Item;
    name: string;
    estimatedQuantity: number;
    imageUrlThumb: string | undefined;
    imageUrlFull: string | undefined;
    tags: Array<TrackedItem>;
}

export default defineComponent({
    name: 'receiving-details',
    components: {
        BSpinner,
        ReceivingTransactionForm,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        BTable,
        SafetyInspectionQuestionnaire,
        SimpleDatePopover,
        BModal,
        QuantityPicker,
        BButton,
        Thumbnail,
        TrackedItemTagModal,
        AllOrOnRouteItemSelector,
    },
    setup() {
        const { carrierStore } = CoreStore.getInstance();
        const receivingService = new ReceivingService();
        const labelGenerationService = new LabelGenerationService();
        const safetyInspectionService = new SafetyInspectionService();
        const reservationService = new ReservationService();
        const { setLoading } = useLoading();
        const { confirm } = useDialogBox();
        const { userLocation, forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;
        const notification = useNotification();

        function initNewShipment(): Shipment {
            const shipment = new Shipment();
            shipment.arrivalDate = new Date();

            const transaction = new Transaction();
            transaction.arrivedAt = shipment.arrivalDate;
            transaction.status = TransactionStatus.DELIVERY_CONFIRMED;

            shipment.addTransaction(transaction);

            return shipment;
        }

        const state = reactive<State>({
            newShipment: initNewShipment(),
            disableSave: false,
            isDownloading: false,
            showModal: false,
            modalTitle: getTitleCaseTranslation('core.common.receiving'),
            isEstimate: false,
            isSort: false,
            labelQuantity: undefined,
            showPrintLabelsModal: false,
            printingLabels: false,
            transactionId: undefined,
            showInboundModal: false,
            inboundShipments: [],
            carriers: carrierStore.carriers,
            fromLocation: new Location(),
            preUnloadSafetyInspection: new SafetyInspection(),
            showPreUnloadSafetyInspection: true,
            showEquipmentInspection: true,
            loading: false,
            showItemSearch: false,
            unloadComplete: false,
            showPostUnloadSafetyInspection: false,
            postUnloadSafetyInspection: new SafetyInspection(),
            submittingEstimate: null,
            tags: [],
            inboundPlannedReservations: [],
            plannedReservation: null,
            isDirty: true,
        });

        const { validateForm, validationResult } = useValidator<Shipment>('new-receiving-shipment');
        const tagListener = new TagListener(searchItem);
        const tags = computed((): Array<TrackedItem> => state.tags as Array<TrackedItem>);

        const inboundsFromLocation = computed(
            (): Array<InboundShipment> => {
                if (state.fromLocation.id > 0) {
                    return state.inboundShipments.filter((ib) => ib.fromLocationId === state.fromLocation.id);
                }
                return [];
            },
        );

        const inboundPlannedReservationsFromLocation = computed(
            (): Array<PlannedReservation> => {
                if (state.fromLocation.id > 0) {
                    return state.inboundPlannedReservations.filter((ib) => ib.fromLocation.id === state.fromLocation.id && ib.carrier.id === state.newShipment.carrierId);
                }
                return [];
            },
        );

        const inboundShipmentFields = computed(
            (): Array<BTableField<InboundShipment & { action: string }>> => {
                const baseFields: Array<BTableField<InboundShipment & { action: string }>> = [
                    {
                        key: 'transactionId',
                        label: getTitleCaseTranslation('core.domain.transactionNumberShort'),
                    },
                    {
                        key: 'licensePlateNumber',
                        label: getTitleCaseTranslation('core.domain.licenseNumberShort'),
                    },
                    {
                        key: 'seal',
                        label: getTitleCaseTranslation('core.domain.seal'),
                    },
                    {
                        key: 'actualDepartureDate',
                        label: getTitleCaseTranslation('core.domain.shipDate'),
                    },
                    {
                        key: 'carrier',
                        label: getTitleCaseTranslation('core.domain.carrier'),
                    },
                    {
                        key: 'action',
                        label: ' ',
                        width: '80px',
                    },
                ];

                return baseFields;
            },
        );

        const inboundPlannedReservationFields = computed(
            (): Array<BTableField<PlannedReservation & { action: string }>> => {
                const baseFields: Array<BTableField<PlannedReservation & { action: string }>> = [
                    {
                        key: 'reservationDateTime',
                        label: getTitleCaseTranslation('core.domain.reservationDateTime'),
                    },
                    {
                        key: 'carrierName',
                        label: getTitleCaseTranslation('core.domain.carrier'),
                    },
                    {
                        key: 'carrierReferenceNumber',
                        label: getTitleCaseTranslation('core.domain.carrierReferenceNumberShort'),
                    },
                    {
                        key: 'trailerNumber',
                        label: getTitleCaseTranslation('core.domain.trailer'),
                    },
                    {
                        key: 'partnerReferenceNumber',
                        label: getTitleCaseTranslation('core.domain.partnerReferenceNumberShort'),
                    },
                    {
                        key: 'action',
                        label: ' ',
                        width: '80px',
                    },
                ];

                return baseFields;
            },
        );

        const transaction = computed(
            (): Transaction => {
                if (state.newShipment.transactions.length > 0) {
                    return state.newShipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );

        const tableData = computed(
            (): Array<ItemTableData> => {
                if (transaction.value && transaction.value.transactionLines && !state.loading) {
                    return mapTransLinesToTableData(transaction.value.transactionLines as Array<TransactionLine>);
                }
                return [];
            },
        );

        const fields = computed(
            (): Array<BTableField<ItemTableData & { action: string }>> => {
                const baseFields: Array<BTableField<ItemTableData & { action: string }>> = [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.domain.image'),
                        width: '70px',
                        ignoreSort: true,
                    },
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'estimatedQuantity',
                        width: '450px',
                        label: getTitleCaseTranslation('core.common.estimatedQuantityShort'),
                    },
                ];
                return baseFields;
            },
        );

        onBeforeMount(async () => {
            tagListener.startListener();
            const response = await receivingService.getInboundShipments();
            if (response.success && response.inboundShipments) {
                state.inboundShipments = response.inboundShipments;
            }

            const plannedReservations = await reservationService.getPlannedReservationsByServiceCenterForTimeRange(userLocation.id, new Date(), 12);
            state.inboundPlannedReservations = plannedReservations;

            state.newShipment.transactions[0].toLocation = userLocation;
        });

        onMounted(async () => {
            if (!forkliftCertified) {
                confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationMissing'),
                    vHtml: true,
                });

                state.isDirty = false;
                router.push({ name: FloorTrakRouteTypes.HOME });
            } else if (!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationExpired'),
                    vHtml: true,
                });

                state.isDirty = false;
                router.push({ name: FloorTrakRouteTypes.HOME });
            }
        });

        onBeforeUnmount(() => tagListener.stopListener());

        function addTag(trackedItem: TrackedItem): void {
            state.isDirty = true;
            transaction.value.addEstimatedItemQty(trackedItem.item, 1);
            transaction.value.addTrackedItem(trackedItem);
            state.tags.push(trackedItem);
        }

        const tagHandler = new FloortrakReceivingTagScanHandler(transaction, tags, addTag);

        async function searchItem(barcodes: Array<string>) {
            await tagHandler.performSearch(barcodes);
        }

        function getCurrentTagsForItem(item: Item): Array<TrackedItem> {
            return state.tags.filter((tag) => tag.item.id === item.id);
        }

        function removeTag(tag: TrackedItem) {
            state.isDirty = true;
            transaction.value.removeTrackedItem(tag);
            transaction.value.addEstimatedItemQty(tag.item, -1);
            const stateTagIndex = state.tags.findIndex((x) => x.id === tag.id);
            state.tags.splice(stateTagIndex, 1);
        }

        function onCancel() {
            state.isDirty = false;
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function onExit(): void {
            state.modalTitle = getTitleCaseTranslation('core.button.saveAndExit');
            state.showModal = true;
            state.isEstimate = false;
            state.isSort = false;
            state.isDownloading = false;
        }

        function onSort(): void {
            state.modalTitle = getTitleCaseTranslation('core.button.saveAndSort');
            state.showModal = true;
            state.isEstimate = false;
            state.isSort = true;
            state.isDownloading = false;
        }

        async function finalizeEstimate() {
            const confirmFinalize = await confirm({
                title: getTitleCaseTranslation('core.domain.finalizeEstimate'),
                message: getTranslation('core.domain.confirmFinalizeMessage'),
            });

            if (confirmFinalize) {
                state.loading = true;
                const createResponse = await createShipment();

                if (createResponse.success) {
                    // findReceivingShipment properly hydrates the DTOs to finalize the estimate
                    let updatedShipment = await receivingService.findReceivingShipment(state.transactionId!, ReceivingAction.ESTIMATE);

                    if (updatedShipment) {
                        const adjustments = await receivingService.blindReceiptAdjustments(updatedShipment.transactions[0]);
                        if (adjustments.success) {
                            // we have to pull the most up-to-date record from the database to avoid confusing EF
                            updatedShipment = await receivingService.findReceivingShipment(state.transactionId!, ReceivingAction.ESTIMATE);
                            if (updatedShipment) {
                                state.newShipment = updatedShipment; // retains the data in the form when we finalize it
                                const response = await receivingService.finalizeEstimate(updatedShipment.transactions[0]);
                                if (response.success) {
                                    state.newShipment.transactions[0].isEstimateFinalized = true;
                                    state.newShipment.transactions[0] = response.transaction;
                                }
                            }
                        }
                    }
                }
                state.isDirty = false;
                state.loading = false;
            }
        }

        function onModalCancel(): void {
            state.showModal = false;
        }

        async function downloadTransactionReceipt(savedShipment: Shipment) {
            if (savedShipment.transactions?.length) {
                await receivingService.getTransactionReceipt(savedShipment.transactions[0].id);
            }
        }

        async function createShipment(): Promise<{ success: boolean; transactionNumber: number }> {
            state.disableSave = true;
            validateForm(state.newShipment as Shipment);

            if (validationResult.isValid) {
                // transactionLine increments actualQuantity for tag scans, this clears that value
                // having an actualQuantity before put away and sort causes undesired behavior
                transaction.value.clearActualQuantityForReceiving();
                const response = await receivingService.createReceivingShipment(state.newShipment as Shipment);
                if (response.success) {
                    state.disableSave = false;
                    state.isDownloading = true;
                    setLoading(true);
                    await safetyInspectionService.associateShipmentWithTrailerInspection(response.shipment.id!, state.preUnloadSafetyInspection.id);
                    await safetyInspectionService.associateShipmentWithTrailerInspection(response.shipment.id!, state.postUnloadSafetyInspection.id);
                    if (state.plannedReservation) {
                        await reservationService.associatePlannedReservationWithShipment(state.plannedReservation!.id!, response.shipment.id!);
                    }
                    await downloadTransactionReceipt(response.shipment);
                    setLoading(false);
                    state.isDownloading = false;
                    const transactionNumber = response.shipment.transactions[0].id;
                    state.transactionId = transactionNumber;
                    return {
                        success: response.success,
                        transactionNumber,
                    };
                }
            }
            state.showModal = false;
            state.disableSave = false;

            return {
                success: false,
                transactionNumber: 0,
            };
        }

        async function proceedToSort() {
            await router.push({
                name: FloorTrakRouteTypes.RECEIVING.SORT,
                params: { transactionNumber: state.transactionId },
            });
        }

        async function goHome() {
            await router.push({ name: FloorTrakRouteTypes.HOME });
        }

        async function saveAndProceed() {
            state.loading = true;
            state.isDirty = false;

            if (!state.newShipment.transactions[0].isEstimateFinalized) {
                const response = await createShipment();

                if (response.success) {
                    // findReceivingShipment hydrates the DTO values
                    const shipment = await receivingService.findReceivingShipment(response.transactionNumber, ReceivingAction.ESTIMATE);
                    if (shipment) {
                        await receivingService.blindReceiptAdjustments(shipment.transactions[0]);
                    }
                    state.showModal = false;
                    state.transactionId = response.transactionNumber;
                    state.showPrintLabelsModal = true;
                }
            }

            if (state.isSort) {
                await proceedToSort();
            } else {
                goHome();
            }

            state.loading = false;
        }

        async function printLabels() {
            state.showPrintLabelsModal = false;

            if (!state.transactionId || !state.labelQuantity) {
                return;
            }

            state.printingLabels = true;
            const labelUrl = await labelGenerationService.getInboundLabelsUrl(state.transactionId, state.labelQuantity!);
            state.printingLabels = false;
            state.labelQuantity = undefined;
            if (labelUrl) {
                window.location.href = labelUrl;
            }
            await goHome();
        }

        function checkForInboundShipments(fromLocation: Location) {
            state.fromLocation = fromLocation;
            state.showInboundModal = inboundsFromLocation.value.length > 0 || inboundPlannedReservationsFromLocation.value.length > 0;
        }

        function ignoreInboundShipments() {
            state.showInboundModal = false;
        }

        async function useExistingInboundShipment(transactionNumber: number) {
            state.isDirty = false;
            await router.push({
                name: FloorTrakRouteTypes.RECEIVING.EXISTING,
                params: { transactionNumber },
            });
        }

        async function useExistingPlannedReservation(reservationId: number) {
            const plannedReservation = inboundPlannedReservationsFromLocation.value.find((r) => r.id === reservationId);
            state.newShipment.carrierReferenceNumber = plannedReservation!.carrierReferenceNumber;
            state.newShipment.transactions[0].partnerReferenceNumber = plannedReservation!.partnerReferenceNumber;
            state.newShipment.transactions[0].arrivedAt = state.newShipment.arrivalDate ?? new Date();
            state.plannedReservation = plannedReservation!;
            state.plannedReservation.reservationDateTime = state.newShipment.arrivalDate ?? new Date();
            state.showInboundModal = false;
        }

        async function cancelPreUnloadSafetyInspection() {
            state.isDirty = false;
            await router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function passPreUnloadSafetyInspection(inspection: SafetyInspection) {
            state.preUnloadSafetyInspection = inspection;
        }

        function changeLineQuantity(item: Item, quantity: number) {
            const currentTags = getCurrentTagsForItem(item);
            if (quantity < currentTags.length && currentTags.length > 0) {
                notification.showError(`${getTranslation('core.validation.qtyCannotBeLessThanTotalTagsForItem', quantity)} (${currentTags.length})`);
            } else {
                transaction.value.setEstimatedItemQty(item, quantity);
            }
        }

        function mapTransLinesToTableData(lines: Array<TransactionLine>): Array<ItemTableData> {
            return lines.map((line) => ({
                item: line.item,
                name: line.item.name ? line.item.name : '',
                estimatedQuantity: line.estimatedQuantity ? line.estimatedQuantity : 0,
                tags: getCurrentTagsForItem(line.item),
                imageUrlFull: line.item.imageUrlFull ? line.item.imageUrlFull : '',
                imageUrlThumb: line.item.imageUrlThumb ? line.item.imageUrlThumb : '',
            }));
        }

        function openItemSearch() {
            state.showItemSearch = true;
        }

        async function addItem(data: { item: Item; quantity: number }) {
            if (transaction.value) {
                state.isDirty = true;
                transaction.value.addEstimatedItemQty(data.item, data.quantity);
            }
            state.showItemSearch = false;
        }

        function showPostUnloadSafetyInspection() {
            validateForm(state.newShipment as Shipment);
            if (validationResult.isValid) {
                state.showPostUnloadSafetyInspection = true;
            }
        }

        async function unloadComplete(inspection: SafetyInspection) {
            state.postUnloadSafetyInspection = inspection;
            state.unloadComplete = true;
        }

        async function confirmLeave() {
            if (state.isDirty) {
                return confirm({
                    title: getTitleCaseTranslation('core.common.areYouSure'),
                    message: getTranslation('core.common.allUnsavedDataWillBeLostWhenYouLeaveThisPage'),
                });
            }
            return true;
        }

        onBeforeRouteLeave(async () => confirmLeave());

        return {
            state,
            inboundShipmentFields,
            onCancel,
            finalizeEstimate,
            onExit,
            saveAndProceed,
            onModalCancel,
            onSort,
            getTranslation,
            getTitleCaseTranslation,
            validationResult,
            Permissions,
            printLabels,
            goHome,
            ignoreInboundShipments,
            useExistingInboundShipment,
            cancelPreUnloadSafetyInspection,
            checkForInboundShipments,
            inboundsFromLocation,
            passPreUnloadSafetyInspection,
            SafetyInspectionType,
            tableData,
            fields,
            changeLineQuantity,
            openItemSearch,
            addItem,
            ItemType,
            unloadComplete,
            showPostUnloadSafetyInspection,
            removeTag,
            inboundPlannedReservationFields,
            inboundPlannedReservationsFromLocation,
            formatDateTimeWithPeriodUI,
            useExistingPlannedReservation,
        };
    },
});
