import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form = _resolveComponent("b-form")!

  return (_openBlock(), _createBlock(_component_b_form, { class: "container-fluid" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            "model-value": _ctx.state.transaction.fromLocation.name,
            loading: _ctx.state.loading,
            cols: "6",
            data: _ctx.state.fromLocations,
            "search-by": "name",
            "empty-text": _ctx.getTranslation('core.validation.noLocationsAssociated'),
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.fromLocation'),
            class: "form-col",
            error: _ctx.validationResult?.model.fromLocation,
            onOnSelect: _ctx.selectFromLocation
          }, null, 8, ["model-value", "loading", "data", "empty-text", "label", "error", "onOnSelect"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.state.transaction.toLocation.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.transaction.toLocation.name) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.toLocation'),
            cols: "6",
            disabled: "",
            class: "form-col"
          }, null, 8, ["modelValue", "label"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            cols: "6",
            "model-value": _ctx.shipment.carrierName,
            data: _ctx.carriers,
            "search-by": "name",
            "empty-text": _ctx.getTitleCaseTranslation('core.validation.noCarriers'),
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
            class: "form-col",
            error: _ctx.validationResult?.model.carrier,
            onOnSelect: _ctx.selectCarrier
          }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "error", "onOnSelect"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.carrierReferenceNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shipment.carrierReferenceNumber) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.shippingReferenceNumber'),
            cols: "3",
            class: "form-col"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_date_input, {
            "model-value": (_ctx.state.transaction.shipDate as Date | undefined),
            "input-format": "MM-dd-yyyy, h:mm:ss a",
            "starting-view": "day",
            "minimum-view": "time",
            label: _ctx.getTitleCaseTranslation('core.domain.shipDate'),
            cols: "3",
            class: "form-col",
            "disabled-dates": _ctx.state.nonWorkDays,
            error: _ctx.validationResult?.model.transactions,
            "lower-limit": _ctx.state.earliestPossibleShipDate,
            "upper-limit": (_ctx.shipment.arrivalDate as Date | undefined),
            "onUpdate:modelValue": _ctx.setShipDate
          }, null, 8, ["model-value", "label", "disabled-dates", "error", "lower-limit", "upper-limit", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_date_input, {
            "model-value": (_ctx.shipment.arrivalDate as Date | undefined),
            "input-format": "MM-dd-yyyy, h:mm:ss a",
            "starting-view": "day",
            "minimum-view": "time",
            label: _ctx.getTitleCaseTranslation('core.domain.dockArrivalTime'),
            cols: "3",
            class: "form-col",
            "lower-limit": _ctx.arrivalDateLowerLimit,
            "upper-limit": _ctx.arrivalDateUpperLimit,
            "onUpdate:modelValue": _ctx.setArrivalDate
          }, null, 8, ["model-value", "label", "lower-limit", "upper-limit", "onUpdate:modelValue"]),
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            cols: "3",
            "model-value": _ctx.shipment.dockName,
            data: _ctx.state.docks,
            "search-by": "name",
            "empty-text": _ctx.getTitleCaseTranslation('core.validation.noDocks'),
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.dock'),
            class: "form-col",
            error: _ctx.validationResult?.model.dock,
            onOnSelect: _ctx.selectDock
          }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "error", "onOnSelect"]),
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            cols: "3",
            "model-value": _ctx.shipment.trailerTypeName,
            data: _ctx.state.trailerTypes,
            "search-by": "name",
            "empty-text": _ctx.getTitleCaseTranslation('core.validation.noTrailerTypes'),
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.trailerType'),
            class: "form-col",
            onOnSelect: _ctx.selectTrailerType
          }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "onOnSelect"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.trailer,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shipment.trailer) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.trailerNumber'),
            cols: "3",
            class: "form-col"
          }, null, 8, ["modelValue", "label"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.licensePlateNumber,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.shipment.licensePlateNumber) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.licensePlateNumber'),
            cols: "3",
            class: "form-col"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.driver,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.shipment.driver) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.driver'),
            cols: "3",
            class: "form-col"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.seal,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shipment.seal) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.sealNumberShort'),
            cols: "3",
            class: "form-col"
          }, null, 8, ["modelValue", "label"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_textarea, {
            id: "input-notes",
            modelValue: _ctx.shipment.deliveryNotes,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.shipment.deliveryNotes) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.notes'),
            rows: "4",
            "max-length": 255
          }, null, 8, ["modelValue", "label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}